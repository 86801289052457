<script>




export default {
    name: 'CurrencyBox',
    
    props: {
        currencyName: String,
        amount: Number,
        unit: String,
        imagePath: String
    },
    
    
};
</script>

<template>

    <div style="display:flex; flex-direction:column; width: 30%; ">

        <div style="text-align: center;
    font-size: 1.15vw;
    font-weight: 450; color:#AFAFAF;">{{  currencyName  }}</div>

        <div class="currency-box">
            <img :src="require(`@/assets/sprites/${imagePath}`)" style="width: 20%;">
            <div>
                <div style="color: #FFFFFF;">{{amount}} </div>
                <div style="margin-left: 10%; color: #FFFFFF;">{{  unit  }}</div>
            </div>
        </div>
    </div>

</template>

<style>
.currency-box {
    display: flex;
    align-items: center;
    width: 100%;

    justify-content: space-between;
    background-color: #3D3849;
    padding: 8px 18px;
    border-radius: 25px;
}


.currency-box>div {
    width: 75%;
    display: flex;

    justify-content: end;
}
</style>