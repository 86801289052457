<script>



export default {
    name: 'TreasureItem',
    props:  {
        itemName: String,
        amount: Number,
        unit: String,
        imagePath: String
    },
    methods: {
        close() {
            this.$emit('close');
        },
        // claimBeaterToken: async function() {

        //     const ethereum = window.ethereum
        //     await ethereum.enable()
        //     let provider = new ethers.providers.Web3Provider(ethereum)

        //     const params = new URLSearchParams({
        //         walletAddress: account
        //     })
        //     let response = await fetch(
        //         `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/game-token/signature?${params}`, {
        //         mode: 'cors',
        //     })

        //     let responseJson = await response.json()
        //     const { amount, signature } = responseJson.data

        //     if (typeof window.ethereum !== 'undefined') {
        //         const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        //         const account = accounts[0]

        //         if (!account) {
        //             alert('No account found')
        //         }

        //         try {
        //             const signer = provider.getSigner()
        //             const claimGameToken = new ethers.Contract(
        //                 '0x02185C2C5933a3AAA9AC945374Cd82AA371e6197', // game token address
        //                 [{
        //                     'inputs': [
        //                         {
        //                             'internalType': 'address',
        //                             'name': 'to',
        //                             'type': 'address'
        //                         },
        //                         {
        //                             'internalType': 'uint',
        //                             'name': 'maxAmount',
        //                             'type': 'uint'
        //                         },
        //                         {
        //                             'internalType': 'bytes',
        //                             'name': 'signature',
        //                             'type': 'bytes'
        //                         }
        //                     ],
        //                     'name': 'claim',
        //                     'outputs': [],
        //                     'stateMutability': 'payable',
        //                     'type': 'function'
        //                 }],
        //                 signer)

        //             const hash = await claimGameToken.claim(
        //                 account,
        //                 Number(amount),
        //                 signature)

        //             const putRequestOptions = {
        //                 mode: 'cors',
        //                 method: 'PUT',
        //                 headers: { 'Content-Type': 'application/json' },
        //                 body: JSON.stringify({
        //                     walletAddress: account,
        //                     amount
        //                 })
        //             }
        //             response = await fetch(
        //                 `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/game-token/claim`,
        //                 putRequestOptions
        //             )
        //             responseJson = await response.json()
        //             const responseMessage = responseJson.data

                    
        //         } catch (e) {
        //             console.error(e)
        //             let errorMessage = ''
        //             if (e.message.toLowerCase().includes("insufficient")) {
        //                 this.processingText = "Insufficient Balance"
        //             } else {
        //                 errorMessage = e && e.error ?
        //                 e.message.length > 60 ? 
        //                     `${e.message.slice(0, 60)}...` : e.message : ''
        //                 this.processingText = `${e.code}: ${errorMessage}`
        //             }
        //             this.processingText = `${e.code}: ${errorMessage}`
        //             this.showModal = true
        //         }
        //     }
        // }
    },
};

</script>


<template>

    <div class="treasure-item">
        <img  :src="require(`@/assets/sprites/${imagePath}`)" style="height: 20%; ">
        <div>
            
            <h5 style="margin: 0; font-size: 1.4vw; text-align: center; vertical-align: middle; color: #FFFFFF;">{{ itemName }}</h5>
            <p style="font-size: 1.1vw; text-align: center; color: #FFFFFF;">{{ amount }} {{ unit }}</p>
        </div>
        <!-- <div style=" display: flex; align-items: center; justify-content: center; width: 100%;">
            <p style="width: 65%; font-size: 1.2vw; text-align: end; color: #FFFFFF;">{{ amount }}</p>
            <p style="width: 30%; font-size: 1.2vw; text-align: start; margin-left: 5px; color: #FFFFFF;">{{ unit }}</p>
        </div> -->
        <!-- <img @click="claimBeaterToken" src="@/assets/sprites/13_INVENTORY/CLAIM_BTN.png"
            style="cursor: pointer; width:70%"> -->
        <!-- <button id="btn-claim-token" @click="claimBeaterToken">Claim</button> -->
    </div>
    
</template>

<style>




</style>