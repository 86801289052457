<template>
  <div class="news-bg py-5">
    <div class="d-flex justify-content-evenly">
      <img class="img-fluid" src="@/assets/sprites/11_PARTNER/OUR_PARTNERS.png" />
    </div>
    <div class="d-flex justify-content-center flex-wrap pb-3">
      
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_amd.png"
        onclick="window.open('https://www.amdthailand.com/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_loga.png"
        onclick="window.open('https://www.logaworld.com/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_egg.png"
        onclick="window.open('https://egg.network/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_15g.png"
        onclick="window.open('http://www.15g-earning.com/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_boxtradex.png"
        onclick="window.open('https://www.boxtradex.com/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_vac.png"
        onclick="window.open('https://www.vacthailand.com/','mywindow');" style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_playgound.png"
        onclick="window.open('https://playgroundmmrpv.com/','mywindow');" style="cursor: pointer;" />

    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_okc.png"
        onclick="window.open('https://www.okx.com/okc/','mywindow');" style="cursor: pointer;" />

    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_enzac.png"
        onclick="window.open('https://www.enzacresearch.com/','mywindow');" style="cursor: pointer;" />

    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_acebeaker.png"
        style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_ace_stu.png"
        style="cursor: pointer;" />
    
    <img class="gameinfo-item px-1 col-md-4" src="@/assets/sprites/11_PARTNER/partner_acecrypto.png"
        style="cursor: pointer;" />
    </div>
  </div>
</template>

<style>

</style>