<script>
import AccountMenu from '@/components/AccountMenu.vue'
import AccountConnected from '@/components/AccountConnected.vue'



export default {
    name: 'AccountNav',
    components: { AccountMenu, AccountConnected },
    // computed:{
    //     walletConnected(){
            
    //         return this.$accWallet !== 'NONE' ? false : false
    //     }
    // },
    // mounted(){
    //     console.log(this.$accWallet !== 'NONE' ? false : false)
    // }
    
}

</script>

<template>
    <div class="account-navbar">
        <div id="navbar-left">

            <router-link to="/" style="width: 10%;">

                <img class="nav-logo" src="@/assets/sprites/01_HEAD_PART/02_HEAD_logotab.png" alt="Buddy Beater"
                    style="width: 100%; cursor: pointer;" />
            </router-link>
            <router-link to="/" style="width: 2%;">

                <img class="nav-home" src="@/assets/sprites/14_NAVIGATION_BAR/HOME_BUTTON.png" alt="Home"
                    style="width: 100%; cursor: pointer;">
            </router-link>

            <ul>
                <router-link to="">
                    <button style="color: black; cursor: not-allowed;">Store</button>
                </router-link>
                <router-link to="/treasure_box">
                    <button>Treasure Box</button>
                </router-link>
                <router-link to="">
                    <button style="color: black; cursor: not-allowed;">Inventory</button>
                </router-link>
                <router-link to="">
                    <button style="color: black; cursor: not-allowed;">Manage Account</button>
                </router-link>

            </ul>
        </div>

        <div id="navbar-right">
            <img src="@/assets/sprites/14_NAVIGATION_BAR/DISCORD_BUTTON.png" alt="Buddy Beater Discord Community"
                style="width: 13%;">
                <AccountConnected></AccountConnected>
                <!-- <AccountConnected v-if="walletConnected()"></AccountConnected>
                <AccountMenu v-else @click="connectWallet()"></AccountMenu> -->
            
        </div>
    </div>
</template>

<style>
.account-navbar {
    position: relative;
    display: flex;
    align-items: center;

    width: 100%;
    height: 4.5vw;



    /* margin-top: 120px; */

    background-color: #3D3849;
}

#navbar-left {
    position: absolute;
    left: 2%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 60%;
    height: 100%;
}



#navbar-left>ul {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 50%;
    margin: 0;

}


#navbar-left>ul>*>button {
    width: 100%;
    height: 100%;

    font-size: .85vw;
    color: #CECECE;
    background-color: transparent;
    border: 0;
}





#navbar-right {
    position: absolute;
    right: 5%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 25%;
    height: 100%;
}
</style>