<script>
    export default {
    name: 'GachaShowcase',
    props: ['itemId'],
    methods: {
        getImageUrl(itemId) {
            
            return require('../assets/sprites/16_STORE/gacha_showcase_items/show_'+ itemId + '.png')
            
            
        }
    }
}
</script>

<template>

    <div id="showcase_container">
        <img src="@/assets/sprites/16_STORE/gacha_showcase_frame.png" style="position: absolute;">
        <img :src="getImageUrl(itemId)" style="width: 60%; left: 20%; top: 4.5%;">


    </div>

</template>

<style>

    #showcase_container{
        position: absolute;
        height: 100%;
    }


    #showcase_container>img{
        position: absolute;
        width: 100%;
    }

</style>