<template>
  <div class="wrapper slide-wrapper">
    <div class="main-column">
      <div class="text-center vdo-top-arrow">
        <img 
          @click="nextSlide"
          class="vdo-slide-arrow" 
          style="visibility: hidden"
          src="@/assets/sprites/02_PIC_VDO/30_UPbutton.png" />
      </div>
      <div class="vdo-container">
        <Splide
          class="main-slide"
          :class="{ 'with-frame': showFrame  }"
          :options="options"
          :extensions="extensions"
          ref="main"
        >
          <SplideSlide v-for="( id, index ) in videos" :key="id" :data-splide-youtube="id">
            <img
              @frameClicked="emitClick"
              :src="`https://i3.ytimg.com/vi/${ id }/maxresdefault.jpg`"
              :alt="`YouTube Sample ${ index + 1 }`"
            >
          </SplideSlide>
        </Splide>
        <img
            v-if="showFrame"
            src="@/assets/sprites/02_PIC_VDO/29_VDOframe.png"
            class="vdo-image-frame"
            alt="vdo frame"
          >
      </div>
      <div class="text-center vdo-bottom-arrow">
        <img 
          @click="nextSlide"
          class="vdo-slide-arrow" 
          style="visibility: hidden"
          src="@/assets/sprites/02_PIC_VDO/30_UPbutton.png" />
      </div>
    </div>

    <div class="thumb-column">
      <div class="text-center vdo-top-arrow">
        <img 
          @click="prevSlide"
          class="vdo-slide-arrow" 
          src="@/assets/sprites/02_PIC_VDO/30_UPbutton.png" />
      </div>
      <Splide
        class="thumb-slide"
        :options="thumbsOptions"
        ref="thumbs"
      >
        <SplideSlide v-for="( id, index ) in videos" :key="id" :data-splide-youtube="id">
          <img
            :src="`https://i3.ytimg.com/vi/${ id }/maxresdefault.jpg`"
            :alt="`YouTube Sample ${ index + 1 }`"
          >
        </SplideSlide>
      </Splide>
      <div class="text-center vdo-bottom-arrow">
        <img 
          @click="nextSlide"
          class="vdo-slide-arrow" 
          src="@/assets/sprites/02_PIC_VDO/31_DOWNbutton.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { Video } from '@splidejs/splide-extension-video'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css'

export default {
  props: ['showFrame'],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      videos: [ 'RAU_WTA3cRI','_mIAtei8eOc', '6T2U4_9Jy6c', 'v7JpAZgdz00', 'fgxePMwg9Lc', 'q-KaJ159cmY', 'ELt40eK-asU'],
      images: [
        'https://img.youtube.com//vi/RAU_WTA3cRI/hqdefault.jpg',
        'https://img.youtube.com/vi/_mIAtei8eOc/hqdefault.jpg'
      ],
      options: {
        rewind     : true,
        heightRatio: 0.5625,
        direction: 'ttb',
        arrows: false,
        pagination: false,
        hideControls: true,
      },
      thumbsOptions: {
        type        : 'slide',
        rewind      : true,
        gap         : '1rem',
        pagination  : false,
        perPage : 3,
        cover       : true,
        focus       : 'center',
        isNavigation: true,
        updateOnMove: true,
        direction: 'ttb',
        height: '100%',
        arrows: false,
      },
      extensions: { Video }
    }
  },
  mounted() {
    const thumbsSplide = this.$refs.thumbs.splide
    if ( thumbsSplide ) {
      this.$refs.main.sync( thumbsSplide )
    }
  },
  methods: {
    emitClick(e) {
      console.log(e)
    },
    nextSlide() {
      this.$refs.thumbs.go('>')
    },
    prevSlide() {
      this.$refs.thumbs.go('<')
    }
  }
}
</script>

<style>
.slide-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.slide-wrapper .main-column {
  width: 60%;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.slide-wrapper .main-slide.splide--slide {
  width: 70%;
}

.slide-wrapper .main-slide.splide--slide.with-frame {
  margin-left: auto;
  margin-right: auto;
}

.vdo-container {
  position: relative;
  width: 100%;
}

.vdo-image-frame {
  position: absolute;
  width: 88%;
  top: -30%;
  left: 4.7%;
  cursor: pointer;
  pointer-events: none;
}

.slide-wrapper .thumb-column {
  width: 15%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.slide-wrapper .thumb-slide.splide--slide {
  width: 100%;
  flex-grow: 4;
}

.vdo-slide-arrow {
  max-width: 50%;
  cursor: pointer;
}

.vdo-top-arrow {
  margin-bottom: 1.2rem;
}

.vdo-bottom-arrow {
  margin-top: 1.2rem;
}

@media screen  and (max-width: 480px){
  .vdo-top-arrow {
    margin-bottom: 0.2rem;
  }

  .vdo-bottom-arrow {
    margin-top: 0.2rem;
  }
}
</style>