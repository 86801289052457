<template>
  <div class="home-bg d-flex pt-2 pb-3 justify-content-center align-items-center">
    <img class="left-seeder"
        src="../assets/sprites/01_HEAD_PART/21_SEEDER_A.png" />

    <div style="display:flex; flex-direction:column; justify-content: center; align-items: center;">
      <img class="learn-more" style="margin:1% 0; cursor: pointer;"
        src="../assets/sprites/01_HEAD_PART/WHITE_PAPER_button01.png" onclick="window.open('https://docsend.com/view/ngnxz6jx35sg7wv9','mywindow');"/>
  
      <router-link style="display: contents;" to="/about">
        <img class="learn-more" style="margin:1% 0;"
            src="../assets/sprites/01_HEAD_PART/23_LEARNMOREbutton_active.png" />
      </router-link>
      
    </div>

    <img class="right-seeder"
        src="../assets/sprites/01_HEAD_PART/22_SEEDER_B.png" />
  </div>
</template>

<style>
.left-seeder {
  max-width: 8%;
  display: inline-block;
  margin-bottom: 8%;
  margin-right: 2%;
}
.learn-more {
  max-width: 60%;
  display: inline-block;
}
.right-seeder {
  max-width: 8%;
  display: inline-block;
  margin-top: 8%;
  margin-left: 2%;
}
</style>