<script>

import AccountNav from '@/components/NavAccount.vue'
import CurrencyBox from '@/components/CurrencyBox.vue'
import TreasureToken from '@/components/TreasureToken.vue'
import TreasureItem from '@/components/TreasureItem.vue'
import { formatEther } from '@ethersproject/units'

const { ethers, BigNumber, utils } = require('ethers')

export default {
    components: { AccountNav, CurrencyBox, TreasureToken, TreasureItem},
    
    data() {
        return{

            currentBeaterToken: 0,
            currentETH: 0,
        }
    },
    methods: {
        formatNumber (num) {
            return parseFloat(num).toFixed(4)
        }, 
        getCurrentToken: async function () {

            const ethereum = window.ethereum
            await ethereum.enable()
            let provider = new ethers.providers.Web3Provider(ethereum)



            if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]

                if (!account) {
                    alert('No account found')
                }

                const wlbl = await ethereum.request({ method: 'eth_getBalance', params:[account, 'latest'] });
                //const wei = parseInt(wlbl, 16);
                // const gwei = (wei / Math.pow(10,9));
                // const eth = (wei / Math.pow(10,18));
                //this.currentETH = formatEther(wlbl)
                this.currentETH = (+formatEther(wlbl)).toFixed(4);

                try {
                    
                    const signer = provider.getSigner()
                    const abiGameToken = new ethers.Contract(
                        '0x02185C2C5933a3AAA9AC945374Cd82AA371e6197', // game token address 
                        [{
                            'inputs': [
                                {
                                    'internalType': 'address',
                                    'name': 'to',
                                    'type': 'address'
                                },
                                {
                                    'internalType': 'uint256',
                                    'name': 'maxAmount',
                                    'type': 'uint256'
                                },
                                {
                                    'internalType': 'bytes',
                                    'name': 'signature',
                                    'type': 'bytes'
                                }
                            ],
                            'name': 'claim',
                            'outputs': [],
                            'stateMutability': 'payable',
                            'type': 'function'
                        }, {
                            'inputs': [{ name: '_owner', type: 'address' }],
                            'name': 'balanceOf',
                            'outputs': [{ name: 'balance', type: 'uint256' }],
                            'stateMutability': 'view',
                            'type': 'function'
                        }],
                        signer)

                    const balance = await abiGameToken.balanceOf(account)       // gwei
                    this.currentBeaterToken = parseFloat(ethers.utils.formatEther(balance)).toFixed(4)
                    
                    
                } catch (e) {
                    console.error(e)
                    let errorMessage = ''
                    if (e.message.toLowerCase().includes("insufficient")) {
                        this.processingText = "Insufficient Balance"
                    } else {
                        errorMessage = e && e.error ?
                            e.message.length > 60 ?
                                `${e.message.slice(0, 60)}...` : e.message : ''
                        this.processingText = `${e.code}: ${errorMessage}`
                    }
                    this.processingText = `${e.code}: ${errorMessage}`
                    this.showModal = true
                }
            }
        }
    },
    beforeMount() {
        this.getCurrentToken()
    },
}
</script>

<template>
    <AccountNav/>

    <section id="treasure-box-body">

        <div id="inventory-box-currency">

            <CurrencyBox currencyName="Beater Token" :amount=this.currentBeaterToken unit="BEAT" imagePath="13_INVENTORY/New_BEATER_TOKEN.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Quinzy Coin" amount=0.0000 unit="QNZ" imagePath="15_TREASURE_BOX/QUINZY_COIN_2.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Ethereum" :amount=this.currentETH unit="ETH" imagePath="13_INVENTORY/ETHEREUM.png">
            </CurrencyBox>
        </div>

        <img src="@/assets/sprites/15_TREASURE_BOX/CHEST_ICON.png" style="width: 9vw;">
        <p style="align-self: center; color: white; margin: 0; font-size: .8vw"><img
                style="width: 1vw; height:auto;" src="@/assets/sprites/12_PROFILE/WARNING_ICON.png"> Treasure chest is a
            place where rewards and
            game rewards are sent after distribution.</p>


        <div id="treasure-box-content">

            <TreasureToken tokenName="Beater Token" unit="BEAT"
                imagePath="13_INVENTORY/BEATER_TOKEN.png" />
            <!-- <TreasureToken tokenName="Quinzy Coin" unit="QNZ" imagePath="13_INVENTORY/QUINZY_COIN.png" />
            <TreasureItem itemName="Cool Boots" unit="EA" imagePath="13_INVENTORY/ITEM_FRAME.png" /> -->
        </div>

    </section>

</template>

<style>
#treasure-box-body {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-evenly;

    width: 100%;
    height: calc(100vh - 4.5vw);

    background-color: #252426;
}

#inventory-box-currency {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 80%;
}

#treasure-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    padding: 2% 0;
    

    background-image: linear-gradient(to right, transparent, rgba(253,253,254, .2) 50%, transparent);
}
</style>