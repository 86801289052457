import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AccountView from '../views/AccountView.vue'
import ManageAccountView from '../views/ManageAccountView.vue'
import TreasureBox from '../views/TreasureBoxView.vue'
import StoreView from '../views/StoreView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView
    },
    {
        path: '/account',
        name: 'manage_account',
        component: ManageAccountView
    },
    {
        path: '/treasure_box',
        name: 'treasure_box',
        component: TreasureBox
    },
    {
        path: '/store',
        name: 'store',
        component: StoreView
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        }
        return { left: 0, top: 0 }
    },
    routes
})

export default router