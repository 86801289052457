<template>
  <div class="home-bg d-flex pt-5 pb-1 px-3 justify-content-center align-items-center">
    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/15_EARNMOREFUN_plate.png" />

    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/16_EARNMOREFUN_pic.png" />
  </div>
  <div class="home-bg d-flex py-1 px-3 justify-content-center align-items-center">
    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/18_HUNTYOURFREIND_pic.png" />

    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/17_HUNTYOURFREIND_plate.png" />
  </div>
  <div class="home-bg d-flex py-1 px-3 justify-content-center align-items-center">
    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/19_GAINMONEY_plate.png" />

    <img class="gameinfo-item px-1"
        src="../assets/sprites/01_HEAD_PART/20_GAINMONEY_pic.png" />
  </div>
</template>

<style>
.gameinfo-item {
  max-width: 45%;
  display: inline-block;
}
</style>