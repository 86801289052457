<template>
  <div class="home-bg vdo-home">
    <Slides :showFrame="true" />
  </div>
</template>

<script>
import Slides from './Slides.vue'

export default {
  components: { Slides }
}
</script>

<style>
.vdo-home {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>