<template>
  <NavHome/>
  <div class="row home-bg g-0">
    <!-- <AboutUsTop /> -->
    <GetToKnow />
    <NewsPaper :onlyFeed="true" />
  </div>
</template>

<script>
import NavHome from '@/components/NavHome.vue'
import AboutUsTop from '@/components/AboutUsTop.vue'
import GetToKnow from '@/components/GetToKnow.vue'
import NewsPaper from '@/components/news-paper/NewsPaper.vue'

export default {
  components: { NavHome, AboutUsTop, GetToKnow, NewsPaper }
}
</script>

<style>

</style>