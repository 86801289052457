<template>
    <div id="gacha-modal-backdrop">
        <div id="gacha-modal" class="animate__animated animate__pulse animate__infinite	">

            <div id="gacha-modal-inner">
                <header id="gacha-modal-header">

                <div style=" display: flex; align-items: center; justify-content: center;">

                    <h3 style="margin: 0; text-align: center; vertical-align: middle;">Treasure Chest</h3>
                    <img src="@/assets/sprites/13_INVENTORY/TREASURE_CHEST.png"
                        style=" width:20%; height: auto; margin-left: 4% ">
                </div>

                </header>

                <section id="gacha-modal-body">
                    <div style=" display: flex; align-items: center; justify-content: center; width: 90%; margin: 0 auto;">
                    
                        <TreasureToken tokenName="Beater Token" amount="99999" unit="BEAT" imagePath="13_INVENTORY/BEATER_TOKEN.png"/>
                   
                    </div>
                </section>
            </div>

            

            <footer id="gacha-modal-footer" style="padding:10px">
                <img src="@/assets/sprites/13_INVENTORY/CLOSE_BTN.png"
                    style="width: auto; height: 70%; max-height: 30px; margin-right: 3%; cursor: pointer;" @click="close">
                <img src="@/assets/sprites/16_STORE/confirm_button_active.png"
                    style="width: auto; height: 70%; max-height: 30px; margin-right: 3%; cursor: pointer;">
                <!-- <img src="@/assets/sprites/13_INVENTORY/CLAIM_All_BTN.png"> -->
            </footer>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import TreasureToken from '@/components/TreasureToken.vue'
import TreasureItem from '@/components/TreasureItem.vue'


export default {
    name: 'TreasureModal',
    components: {
    TreasureToken,
    TreasureItem,

},
    methods: {
        close() {
            this.$emit('close');
        },
       
    },
};
</script>

<style>
#gacha-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

#gacha-modal {
    background: linear-gradient(115.26deg, #E2DCFF -9.01%, #AEA9C4 9.11%, #817C90 27.23%, #6F6B7C 35.83%);
    box-shadow: 2px 2px 20px 1px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 50%;
    height: auto;
    min-height: 45%;


    border-radius: 10px;
}


#gacha-modal-footer {
    display: flex;
    border-top: 1px solid #eeeeee;
    align-items: center;
    justify-content: space-evenly;
    height: 14%;
    
    
}

#gacha-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    padding: 20px 10px;
}



#gacha-modal-inner{
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    
    height: 70%; 
    
    background: rgba(0, 0, 0, 0.2);
    
    border-radius: 20px;
    
    margin: 24px;
    padding-bottom: 2%;
}

</style>