<template>
  <div class="row home-bg py-3 g-0" id="know-beater">
    <div class="col kb-container">
      <img class="img-fluid" 
        src="../assets/sprites/03_Know_Our_BEATER/know-beater-bg.png" alt="cover" />

        <img class="img-fluid kb-char kb-sapien" src="../assets/sprites/03_Know_Our_BEATER/37_SAPIENSbutton.png" @click="charClick('sapien')" />
        <img class="img-fluid kb-char kb-ancient" src="../assets/sprites/03_Know_Our_BEATER/38_ANCIENTbutton.png" @click="charClick('ancient')"/>
        <img class="img-fluid kb-char kb-mutant" src="../assets/sprites/03_Know_Our_BEATER/39_MUTANTbutton.png" @click="charClick('mutant')"/>
        <img class="img-fluid kb-char kb-beast" src="../assets/sprites/03_Know_Our_BEATER/40_BEASTbutton.png" @click="charClick('beast')"/>

        <div class="kb-card">
          <img class="kb-class" v-if="selectedClass === 'sapien'" src="../assets/sprites/03_Know_Our_BEATER/41_SAPIENScard.png" />
          <img class="kb-class" v-if="selectedClass === 'ancient'" src="../assets/sprites/03_Know_Our_BEATER/42_ANCIENTcard.png" />
          <img class="kb-class" v-if="selectedClass === 'mutant'" src="../assets/sprites/03_Know_Our_BEATER/43_MUTANTcard.png" />
          <img class="kb-class" v-if="selectedClass === 'beast'" src="../assets/sprites/03_Know_Our_BEATER/44_BEASTcard.png" />
        </div>
    </div>
    <!-- <div class="col">
      <div class="kb-box"></div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedClass: 'sapien'
    }
  },
  methods: {
    charClick(cl) {
      this.selectedClass = cl
    }
  }
}
</script>

<style scope>
.kb-container {
  position: relative;
}
.kb-char {
  position: absolute;
  z-index: 10;
  width: 11.5%;
  cursor: pointer;
}

.kb-sapien {
  left: 14%;
  top: 42%;
}
.kb-ancient {
  left: 23%;
  top: 52%;
}
.kb-mutant {
  left: 30%;
  top: 35%;
}
.kb-beast {
  left: 36%;
  top: 51%;
}

.kb-card {
  position: absolute;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  left: 50%;
  padding-top: 12%;
}

.kb-class {
  margin-left: 20px;
  width: 80%;
}
</style>