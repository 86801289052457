<template>
  <div class="home-bg bb-cover">
    <img class="cover-bg" 
      src="@/assets/sprites/10_ABOUT_BUDDY_BEATER/91_ABOUTBEATER_mainbg.jpg" alt="cover" />

    <img class="abu-title" 
      src="@/assets/sprites/10_ABOUT_BUDDY_BEATER/92_ABOUTBEATER_head.png" />

    <Slides class="abu-vdo-list" />
    <!-- <video controls autoplay class="coming-soon">
      <source src="@/assets/sprites/10_ABOUT_BUDDY_BEATER/comming_soon_vdo_sm.mp4" type="video/mp4">
    </video> -->
    <!-- <img class="coming-soon" 
      src="@/assets/sprites/10_ABOUT_BUDDY_BEATER/TRAILER_IS_COMING_SOON.gif" /> -->
  </div>
</template>

<script>
import Slides from './Slides.vue'

export default {
  components: { Slides }
}
</script>

<style>
.abu-title {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 30%;
  transform: translate(-50%);
}

.abu-vdo-list {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  padding: 0px 2rem;
}

.coming-soon {
  position: absolute;
  top: 33%;
  left: 50%;
  width: 55%;
  transform: translate(-50%);
}

@media screen  and (max-width: 480px){
  .abu-vdo-list {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 100%;
    transform: translate(-50%);
    padding: 0px 2rem;
  }
}
</style>