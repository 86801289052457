<template>

    <div class="gacha-banner">
      
  
        this is  {{gachaId}} 
   
        
    </div>
  </template>

  
  <script>
  export default {
    props: {gachaId: String},
    
    methods: {
    //   getImageUrl(gachaId) {
        
    //     return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gachaId + '.jpg')
        
    //   },
      
    }
  }
  </script>
  
  <style>
  .gacha-banner {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 8px;
    padding: 4px 4px;
    border: 5px solid transparent;
    border-radius: 12px;
    background-image: 
      linear-gradient(180deg, rgba(252,238,104,1) 0%, rgba(218,114,53,1) 100%),
      linear-gradient(to bottom, rgb(216,121,45), rgb(78,11,6));
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  

  
  </style>