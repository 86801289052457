<template>
  <div class="d-flex justify-content-evenly footer-bg py-5">
    <div class="d-flex flex-column justify-content-center p-3">
      <img class="img-fluid footer-img-block" src="@/assets/sprites/09_FOOTER/82_FOOTER_Quinzylogo.png" />
      <img 
        @click="goToQuinzy"
        class="img-fluid footer-img-block mt-3 quinzy-link" 
        src="@/assets/sprites/09_FOOTER/86_FOOTER_aboutQuinzy_button_active.png" onclick="window.open('http://quinzy.io','mywindow');" style="cursor: pointer;" />
    </div>
    <div class="d-flex flex-column justify-content-center p-3">
      <img class="img-fluid footer-img-block" src="@/assets/sprites/09_FOOTER/83_FOOTER_support.png" />
      <img class="img-fluid footer-img-block mt-3" src="@/assets/sprites/09_FOOTER/85_FOOTER_contactus_button.png" onclick="window.open('http://quinzy.io/support','mywindow');" style="cursor: pointer;" />
    </div>
    <div class="d-flex flex-column justify-content-center p-3">
      <img class="img-fluid footer-img-block" src="@/assets/sprites/09_FOOTER/84_FOOTER_comunity.png" />
      <div style="width: 100%" class="d-flex justify-content-center align-items-center mt-3">
        <img class="footer-img-block footer-social-icon mx-1" src="@/assets/sprites/09_FOOTER/88_FOOTER_discord.png" onclick="window.open('https://discord.gg/VksNcybTQR','mywindow');" style="cursor: pointer;" />
        <img class="footer-img-block footer-social-icon mx-1" src="@/assets/sprites/09_FOOTER/89_FOOTER_twitter.png" onclick="window.open('https://twitter.com/BuddyBeater','mywindow');" style="cursor: pointer;" />
        <img class="footer-img-block footer-social-icon mx-1" src="@/assets/sprites/09_FOOTER/90_FOOTER_youtube.png" onclick="window.open('https://www.youtube.com/channel/UCnqRAijOSGcVYrh5UA_ypvw','mywindow');" style="cursor: pointer;" />
        <img class="footer-img-block footer-social-icon mx-1" src="@/assets/sprites/09_FOOTER/90_1_FOOTER_facebook.png" onclick="window.open('https://www.facebook.com/BuddyBeater','mywindow');" style="cursor: pointer;" />
        <img class="footer-img-block footer-social-icon mx-1" src="@/assets/sprites/09_FOOTER/90_2_FOOTER_telegrem.png" onclick="window.open('https://t.me/BuddyBeater','mywindow');" style="cursor: pointer;" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  methods: {
    goToQuinzy() {
      window.open('http://quinzy.io/')
    }
  }
}
</script>

<style>
.footer-bg {
  background-image: url('@/assets/sprites/09_FOOTER/81_FOOTER_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.footer-img-block {
  display: block;
}
.footer-social-icon {
  max-width: 36px;
  /* max-height: 48px; */
}
.quinzy-link {
  cursor: pointer;
}
</style>