<template>
  <div class="card news-card" style="max-width: 360px;">
    <img :src="imgUrl" class="card-img-top">
    <div class="card-body">
      <!-- <p class="card-text"><strong>Date: </strong>{{ date }}<br><strong>Title: </strong>{{ title }}</p> -->
      <p class="card-text">{{ date }}</p><p><strong>{{ title }}</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['date', 'title', 'imgUrl']
}
</script>

<style>
.news-card {
  background-color: rgba(0, 0, 0, 0) !important;
  border: transparent !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  font-size: 1.4em;
  color: black;
  cursor: pointer;
}

.card-text {
  font-size: 0.8em;
  margin-bottom: 0;
}
</style>