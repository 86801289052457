<script>
    
    export default {
    name: 'GachaCard',
    props: {
        currentBeat: Number,
        boxName: String ,
        price: Number
    },
    data() {
        return {
            isActive1: false,
            isActive10: false,
        }
    },
    methods:{
        handleOrderBtn(){
            
            this.isActive1 = this.currentBeat >= this.price;
            this.isActive10 = this.currentBeat >= this.price*10;
            
        }
    },
    beforeMount(){
        this.handleOrderBtn()
    }
    
    }
</script>

<template>

    <div id="gacha-card-bg" v-bind:style="{ 'background-image': 'url(' + require('@/assets/sprites/16_STORE/gacha_box_cards/' + boxName + '.png') + ')' }">
        
        <div id="price-tag">
            <img src="@/assets/sprites/13_INVENTORY/BEATER_TOKEN.png" style="width:20%">
            <div>{{price.toLocaleString()}}</div>
            <div>BEAT</div>
        </div>
        
        <div id="order-buttons">
            <div :class="{ 'order_button_active': isActive1 }">
                <img src="@/assets/sprites/16_STORE/order_1_new.png" >
                <div id="price-1">{{price.toLocaleString()}}</div>

            </div>
            <div :class="{ 'order_button_active': isActive10 }">
                <img src="@/assets/sprites/16_STORE/order_10_new.png" >
                <div id="price-10">{{(price*10).toLocaleString()}}</div>
            </div>
        </div>
        
    </div>

</template>

<style>

    #gacha-card-bg{
        /* display: flex;
        align-items: center; */
        position: relative;
        width: 25%;
        height: 90%;
        
        
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #price-tag{
        display: flex;
        
        justify-content: space-evenly;
        align-items: center;
        position: absolute;

        font-size: 0.97vw;
        font-weight: 550;

        width: 80%;
        bottom: 28%;
        height: 30%;
        left: 9.5%;
    }

    #order-buttons{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        width: 80%;
        bottom: 5%;
        height: 30%;
        left: 9.5%;
    }

    #order-buttons>div{
        width: 70%;
        cursor: not-allowed;
        
    }


    .order_button_active{
        cursor: pointer!important;
    }

    #order-buttons>div>img{
        width: 100%;
        filter: brightness(80%);
    }

    .order_button_active>img{
        filter: brightness(100%)!important;
    }

    .order_button_active>div{
        color: white!important;
    }

    #order-buttons>div>div{
        position: absolute;
        font-size: 0.85vw;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.329);
    }

    #price-1{
        top: 23%;
        left: 43%;
    }
    
    #price-10{
        top: 68%;
        left: 40%;
    }
</style>