<template>
  <div id="account-btn" class="d-flex align-items-center justify-content-evenly" style="border-radius: 14px; cursor: pointer;" >
      <img src="@/assets/sprites/12_PROFILE/MAIN_PROFILE_PIC.png" alt="profile pic" style="width: 20%">
      <div style="color:#fff; font-size: 1vw; background-color: #C6B999; border-radius: 14px; padding: 2% 4%;">Connect Wallet</div>
      
  </div>
    
  
</template>

<script>
  export default {
    
  }
</script>

<style>
#account-btn {
  width: 60%;
  height: 60%;

  background-color: #F7EBC6;
}
</style>