<template>
  <!-- <div class="gangster-card-bg my-2 mx-1">
    <img style="" 
      :src="getImageUrl(gangId)" />
    <div class="mt-3 mb-0">
      <p><strong>name</strong> {{ name }}<br><strong>class</strong> {{ position }}</p>
    </div>
  </div> -->
  <div class="gangster-card">
    <div class="gangster-info">

      <img class="gangster-img"
          :src="getImageUrl(gangId)" @mouseover="this.hover = true" @mouseleave="this.hover = false"/>
      <!-- <div class="gangster-img" v-bind:style="{ 'background-image': 'require(' + '../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '_hover' + '.jpg' + ')' }">
      </div> -->
 
      <div class="" style="font-size: 1.4em; margin-bottom:0em; margin-top:0em; text-align:center !important">
        <strong>{{ name }}</strong>
      </div>
      <div style="margin-top:0.4em">
        <strong>Class : </strong>{{ position }}
      </div>
    </div>
  </div>
</template>

<!-- <script>
export default {
  props: ['name', 'position', 'gangId'],
  methods: {
    getImageUrl(gangId) {
      return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '.jpg')
    }
  }
}
</script> -->

<script>
export default {
  props: ['name', 'position', 'gangId'],
  data(){
    return{hover: false}
  },
  methods: {
    getImageUrl(gangId) {
      if(this.hover == true){
        return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '.jpg')
      }else{
        return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '_hover' + '.jpg')
      }
    },
    getImageHoverUrl(gangId) {
      // if(this.hover == true){
      // }else{
      //   return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '_hover' + '.jpg')
      // }
       return require('../../assets/sprites/06_BUDDY_GANGSTER/the_gangs/' + gangId + '.jpg')
    }
  }
}
</script>

<style>
.gangster-card {
  max-width: 240px;
  min-height: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 8px;
  padding: 4px 4px;
  border: 5px solid transparent;
  border-radius: 12px;
  background-image: 
    linear-gradient(180deg, rgba(252,238,104,1) 0%, rgba(218,114,53,1) 100%),
    linear-gradient(to bottom, rgb(216,121,45), rgb(78,11,6));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.gangster-info {
  width: 88%;
  padding-top: 8px;
  font-size: 0.85em;
}

.gangster-card-container{
  width: 100%;
  position: relative;
  display: block;
}

.gangster-img {
  width: 100%;
  /* height: 500px; */
  
  /* position: relative; */
  display: block;

  
}

.gangster-img:hover {
  
}

.gangster-img-hover {
  width: 100%;
  aspect-ratio: 1/1;
  
  position: absolute;
  
  display: none;
}
/* .gangster-card-bg {
  background-image: url('@/assets/sprites/06_BUDDY_GANGSTER/65_GANGSTER_template.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 23px 10px 15px 10px;
  display: block; 
} */

</style>