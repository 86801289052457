<script>
import AccountMenu from '@/components/AccountMenu.vue'
import AccountConnected from '@/components/AccountConnected.vue'

//const { ethers, BigNumber, utils } = require('ethers')

export default {
    data(){
        return{
            isConnected : this.$accWallet !== 'NONE' ? true : false
        }
    },

    computed:{
        walletConnected(){
            return this.$accWallet !== 'NONE' ? true : false
        }
    },
    
    methods: {
        goto(idName) {
            const element = document.getElementById('/#' + idName)
            if (element && element.scrollIntoView) {
                console.log('Go:', element)
                element.scrollIntoView()
            }
        },
        connectWallet: async function () {

            const ethereum = window.ethereum
            await ethereum.enable()

            if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]

                if (!account) {
                    alert('No account found')
                } else {
                    
                    this.$accWallet = account
                    this.isConnected = this.$accWallet !== 'NONE' ? true : false
                }

            }
        }

    },

    

    
    

    components: { AccountMenu, AccountConnected }
};


</script>

<template>
    <div class="bb-navbar">
        <img class="img-fluid bb-navbar-bg" src="@/assets/sprites/01_HEAD_PART/01_HEAD_maintab.png" />

        <div class="bb-navbar-icons-pane d-flex align-items-center justify-content-evenly">
            <img class="bb-logo" src="@/assets/sprites/01_HEAD_PART/02_HEAD_logotab.png" />

            <div class="bb-center-menu-pane d-flex align-items-center justify-content-evenly">
                <router-link style="height: 100%" class="d-flex align-items-center" to="/">
                    <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/03_HEAD_HOMEbutton.png"
                        style="cursor: pointer;" />
                </router-link>
                <router-link style="height: 100%" class="d-flex align-items-center" to="/about">
                    <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/04_HEAD_ABOUTbutton.png"
                        style="cursor: pointer;" />
                </router-link>

                <router-link style="height: 100%" class="d-flex align-items-center"
                    :to="{ name: 'home', hash: '#know-beater' }">
                    <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/05_HEAD_BEATERbutton.png"
                        style="cursor: pointer;" />
                </router-link>

                <router-link style="height: 100%" class="d-flex align-items-center"
                    :to="{ name: 'home', hash: '#bb-news' }">
                    <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/06_HEAD_NEWSbutton.png"
                        style="cursor: pointer;" />
                </router-link>

                <router-link style="height: 100% display:none" class="d-flex align-items-center" to="/about">
                    <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/07_HEAD_SUPPORTbutton.png"
                        style="cursor: pointer; display:none" />
                </router-link>

                <img class="bb-menu center-menu" src="@/assets/sprites/01_HEAD_PART/TOP_DOC_button01.png"
                    onclick="window.open('https://quinzy.gitbook.io/buddy-beater/','mywindow');"    style="cursor: pointer; " />
            </div>

            <div class="bb-right-menu-pane d-flex align-items-center justify-content-evenly" style="width: 20%">
                <img class="bb-menu discord" src="@/assets/sprites/01_HEAD_PART/08_DISCORDbutton_active.png"
                    onclick="window.open('https://discord.gg/VksNcybTQR','mywindow');" style="cursor: pointer;" />
                <AccountConnected v-if="this.isConnected"></AccountConnected>
                <AccountMenu v-else @click="connectWallet()"></AccountMenu>
                <!-- <img class="bb-menu wallet" src="@/assets/sprites/01_HEAD_PART/11_WALLETbutton_unactive.png" /> -->
            </div>

            <!-- <AccountMenu style="height: 100% display:none" class="nav-account d-flex align-items-center justify-content-evenly"/> -->



        </div>
    </div>
</template>

<style>


.bb-navbar {
    width: 100%;
    z-index: 999;
    position: absolute;
    margin-top: 8px;
}

.bb-navbar-bg {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bb-navbar-icons-pane {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.bb-center-menu-pane,
.bb-right-menu-pane {
    height: 100%;
    position: absolute;
}

.bb-right-menu-pane {
    right: 10%;
}

.bb-logo {
    height: 70%;
    position: absolute;
    left: 12%;
}

.bb-menu {
    max-height: 25%;
}

.bb-menu.center-menu {
    margin-right: 12px;
    margin-left: 12px;
}

.bb-menu.discord,
.bb-menu.wallet {
    max-height: 50%;
    margin-right: 8px;
    margin-left: 8px;
}

.app-content-view {
    position: absolute;
    top: 0;
}
</style>