<script>
import 'animate.css';
import AccountNav from '@/components/NavAccount.vue'
import CurrencyBox from '@/components/CurrencyBox.vue'
import GachaBanner from '@/components/GachaBanner.vue'
import GachaModal from '@/components/GachaModal.vue'
import GachaShowcase from '@/components/GachaShowcase.vue'
import GachaCard from '@/components/GachaCard.vue'
import { Splide, SplideSlide } from '@splidejs/vue-splide'


import { formatEther } from '@ethersproject/units'

const { ethers, BigNumber, utils } = require('ethers')

export default {
    components: { AccountNav, CurrencyBox, Splide, SplideSlide, GachaBanner, GachaModal, GachaShowcase, GachaCard},
    data() {
    return {
        isGachaModalVisible: false,
        currentBeaterToken: 0,
        currentETH: 0,
      GachaBanners: [
        { id: "01"},
        { id: "02"},
        { id: "03"},
      ],
      slideOptions: {
        rewind: true, 
        pagination: true, 
        arrows: false, 
        perPage: 1,
        
        breakpoints: {
          720: {
            perPage: 2
          },
          480: {
            perPage: 1,
            arrows: true
          }
        }
      },
      StoreItems: [
        { id: "01"},
        { id: "02"},
        { id: "03"},
        { id: "04"},
        { id: "05"},
        { id: "06"},
        { id: "07"},
        { id: "08"},
        { id: "09"},
        { id: "10"},
        { id: "11"},
        { id: "12"},
      ],
      slideOptions_store: {
        rewind: true, 
        pagination: true, 
        arrows: false, 
        
        grid:{
            rows: 2,
            cols: 4,
        },
        breakpoints: {
          720: {
            perPage: 2
          },
          480: {
            perPage: 1,
            arrows: true
          }
        }
      }
    }
  },
    
    methods: {
        formatNumber (num) {
            return parseFloat(num).toFixed(4)
        }, 
        getCurrentToken: async function () {

            const ethereum = window.ethereum
            await ethereum.enable()
            let provider = new ethers.providers.Web3Provider(ethereum)



            if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]

                if (!account) {
                    alert('No account found')
                }

                const wlbl = await ethereum.request({ method: 'eth_getBalance', params:[account, 'latest'] });
                //const wei = parseInt(wlbl, 16);
                // const gwei = (wei / Math.pow(10,9));
                // const eth = (wei / Math.pow(10,18));
                //this.currentETH = formatEther(wlbl)
                this.currentETH = (+formatEther(wlbl)).toFixed(4);

                try {
                    
                    const signer = provider.getSigner()
                    const abiGameToken = new ethers.Contract(
                        '0x02185C2C5933a3AAA9AC945374Cd82AA371e6197', // game token address 
                        [{
                            'inputs': [
                                {
                                    'internalType': 'address',
                                    'name': 'to',
                                    'type': 'address'
                                },
                                {
                                    'internalType': 'uint256',
                                    'name': 'maxAmount',
                                    'type': 'uint256'
                                },
                                {
                                    'internalType': 'bytes',
                                    'name': 'signature',
                                    'type': 'bytes'
                                }
                            ],
                            'name': 'claim',
                            'outputs': [],
                            'stateMutability': 'payable',
                            'type': 'function'
                        }, {
                            'inputs': [{ name: '_owner', type: 'address' }],
                            'name': 'balanceOf',
                            'outputs': [{ name: 'balance', type: 'uint256' }],
                            'stateMutability': 'view',
                            'type': 'function'
                        }],
                        signer)

                    const balance = await abiGameToken.balanceOf(account)       // gwei
                    this.currentBeaterToken = parseFloat(ethers.utils.formatEther(balance)).toFixed(4)
                    
                    
                } catch (e) {
                    console.error(e)
                    let errorMessage = ''
                    if (e.message.toLowerCase().includes("insufficient")) {
                        this.processingText = "Insufficient Balance"
                    } else {
                        errorMessage = e && e.error ?
                            e.message.length > 60 ?
                                `${e.message.slice(0, 60)}...` : e.message : ''
                        this.processingText = `${e.code}: ${errorMessage}`
                    }
                    this.processingText = `${e.code}: ${errorMessage}`
                    this.showModal = true
                }
            }
        },
        showGachaModal() {
            this.isGachaModalVisible = true;
        },
        closeGachaModal() {
            this.isGachaModalVisible = false;
        },
    },
    beforeMount() {
        this.getCurrentToken()
    },
}
</script>

<template>
    <AccountNav/>

    <section id="store-body">

        <div id="inventory-box-currency">

            <CurrencyBox currencyName="Beater Token" :amount=this.currentBeaterToken unit="BEAT" imagePath="13_INVENTORY/New_BEATER_TOKEN.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Quinzy Coin" amount=0.0000 unit="QNZ" imagePath="15_TREASURE_BOX/QUINZY_COIN_2.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Ethereum" :amount=this.currentETH unit="ETH" imagePath="13_INVENTORY/ETHEREUM.png">
            </CurrencyBox>
        </div>

        <div class="banner-panel">
            <!-- <Splide ref="splide" :options="{ ...slideOptions }">
        
                <SplideSlide v-for="GachaBanner in GachaBanners" :key="GachaBanners.id" class="d-flex justify-content-center">
                    <GachaBanner :gachaId="GachaBanners.id"/>
                    <GachaBanner :gachaId="GachaBanner.id"/>

                </SplideSlide>
        
            </Splide> -->
            <div style="position: relative;">
                <img src="@/assets/sprites/16_STORE/gacha_banner_background.png" style="width:100%;">

                <img src="@/assets/sprites/16_STORE/promo_text_head.png" class="animate__animated animate__pulse animate__infinite" style="position: absolute; width:22%; left:13%; top: 9%;">
                <img src="@/assets/sprites/16_STORE/all_gacha_pic.png" style="position: absolute; width:27.5%; left:11%; top: 29%;">
                <img src="@/assets/sprites/16_STORE/promo_text_body.png" style="position: absolute; width:20%; left:15%; top: 70%;">

                <GachaShowcase :itemId="1" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:15%; left:5%;"></GachaShowcase>
                <GachaShowcase :itemId="2" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:40%; left:2%;"></GachaShowcase>
                <GachaShowcase :itemId="3" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:65%; left:5%;"></GachaShowcase>
                <GachaShowcase :itemId="4" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:15%; left:37%;"></GachaShowcase>
                <GachaShowcase :itemId="5" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:40%; left:39%;"></GachaShowcase>
                <GachaShowcase :itemId="6" class="gacha-showcase animate__animated animate__pulse animate__repeat-2" style=" top:65%; left:37%;"></GachaShowcase>
            
                <div id="gacha-right">

                    <div id="gacha-cards">
    
                        
                        <GachaCard :currentBeat="this.currentBeaterToken" :boxName="'platinum'" :price="10000"></GachaCard>
                        <GachaCard :currentBeat="this.currentBeaterToken" :boxName="'gold'" :price="1000"></GachaCard>
                        <GachaCard :currentBeat="this.currentBeaterToken" :boxName="'silver'" :price="500"></GachaCard>
                        <GachaCard :currentBeat="this.currentBeaterToken" :boxName="'bronze'" :price="100"></GachaCard>
    
                    </div>
                    <img src="@/assets/sprites/16_STORE/text_box_rate.png" style="width:70%">
                </div>
            </div>
        </div>

        <!-- <div id="treasure-box-content">

            

        </div> -->

        <div id="store-panel">
            <div id="store-side">

            </div>
            <div id="store-list">
                <Splide ref="splide"  :options="{ ...slideOptions_store }">
        
                    <SplideSlide v-for="StoreItem in StoreItems" :key="StoreItems.id" class="d-flex justify-content-center">
                        <!-- <GachaBanner :gachaId="GachaBanners.id"/> -->
                        <GachaBanner :gachaId="StoreItem.id"/>

                    </SplideSlide>

                 </Splide>
            </div>
        </div>

        <GachaModal v-show="isGachaModalVisible" @close="closeGachaModal"></GachaModal>

    </section>

</template>

<style>
#store-body {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-evenly;

    width: 100%;
    padding-bottom: 3%;

    background-color: #252426;
}

#store-body>div {
    margin: 2.5% 0;
}

.banner-panel{
    width: 100%;
}

#store-panel{
    display: flex;
    

    width: 85%;
    min-height: 500px;
    background-color: aqua;
}



#store-side{

    width: 30%;
    min-height: 500px;
    background-color: burlywood;
}

#store-list{
    width: 100%;
    min-height: 500px;
    background-color: coral;
}

.gacha-showcase{
    position: absolute; 
    width: 7%;
}

#gacha-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 55%;
    height: 100%;
    top: 0;
    right: 0;
}

#gacha-cards{
    display: flex;
    align-items: center;
    justify-content: space-around;
    

    /* position: absolute; */
    width: 100%;
    height: 80%;
    /* top: 0;
    right: 0; */
}
</style>