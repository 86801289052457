<script>

import AccountNav from '@/components/NavAccount.vue'
import CurrencyBox from '@/components/CurrencyBox.vue'

export default {
    components: { AccountNav, CurrencyBox }
}
</script>

<template>
    <AccountNav />

    <section id="manage-account-body">

        <div id="inventory-box-currency">

            <CurrencyBox currencyName="Beater Token" amount=10 unit="BEAT"
                imagePath="15_TREASURE_BOX/BEATER_TOKEN_2.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Quinzy Coin" amount=0.123 unit="QNZ"
                imagePath="15_TREASURE_BOX/QUINZY_COIN_2.png">
            </CurrencyBox>
            <CurrencyBox currencyName="Ethereum" amount=0.11 unit="ETH" imagePath="13_INVENTORY/ETHEREUM.png">
            </CurrencyBox>
        </div>

        <div id="manage-account-setting">

            <div style="display: flex; align-items:center; justify-content:space-evenly; width:100%;">
                <img src="@/assets/sprites/12_PROFILE/PROFILE_PIC_BIG.png" alt="Profile Picture" style="width: 20%;">
                <div id="manage-name">

                    <h3 style="color:#FFFFFF;">Display Name</h3>
                    <p style="color:#FFFFFF;">Your display name will be seen when you play games. Have fun with it!</p>
                    <div id="edit-name">
                        <input type="text" id="name" name="display name">
                        <img id="edit-name-btn" src="@/assets/sprites/12_PROFILE/EDIT_BTN_ENABLED.png"
                            alt="Edit Display Name">
                    </div>

                    <p style="color:#FF5A39;">Warning : Your display name must be following our <a href="#"
                            style="color:#FF5A39;">name policy.</a><img
                            src="@/assets/sprites/12_PROFILE/WARNING_ICON.png"
                            style="width: 5%; height: auto; margin-left: 1%;" alt="name policy" /></p>


                    <p>You can change display name once every 60 days.</p>

                </div>
            </div>
            <div style="width:100%; height: 200px;">
                
            </div>
        </div>



    </section>




</template>

<style>
#manage-account-body {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-evenly;

    width: 100%;
    height: calc(100vh - 4.5vw);

    background-color: #252426;
}

#manage-account-setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    width: 50%;
        
}

#manage-name{
    /* width: 50%; */
}
</style>