<template>

  <NavHome/>
  <div class="container-fluid g-0" >
    <Cover />
    <GameInfo />
    <LearnAbout />
    <CoolVdo />
    <KnowBeater />
    <HowToEarn />
    <!-- <RoadMap /> -->
    <Gangsters />
    <NewsPaper />
    <!-- <Faq /> -->
    <Partner />
    <Footer />
  </div>
</template>

<script>
import NavHome from '@/components/NavHome.vue'
import Cover from '@/components/Cover.vue'
import GameInfo from '@/components/GameInfo.vue'
import LearnAbout from '@/components/LearnAbout.vue'
import CoolVdo from '@/components/CoolVdo.vue'
import KnowBeater from '@/components/KnowBeater.vue'
import HowToEarn from '@/components/HowToEarn.vue'
import RoadMap from '@/components/RoadMap.vue'
import Gangsters from '@/components/buddy-gangster/Gangsters.vue'
import NewsPaper from '@/components/news-paper/NewsPaper.vue'
import Faq from '@/components/Faq.vue'
import Partner from '@/components/Partner.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: { NavHome, Cover, GameInfo, LearnAbout, CoolVdo, KnowBeater, HowToEarn, RoadMap, Gangsters, Faq, NewsPaper, Partner, Footer }
}
</script>
