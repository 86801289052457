<template>
  <div class="home-bg d-flex justify-content-center py-4">
    <img class="gangster-label" src="@/assets/sprites/06_BUDDY_GANGSTER/64_GANGSTER_head.png" />
  </div>
  <div class="home-bg py-3 pb-5 d-flex align-items-center justify-content-evenly ">
    <div class="left-arrow">
      <img 
        @click="prevSlide"
        class="gang-slide-arrow" 
        src="@/assets/sprites/06_BUDDY_GANGSTER/66_GANGSTER_leftbutton.png" />
    </div>
    <div class="gang-panel">
      <Splide 
        ref="splide"
        :options="{ ...slideOptions }">

        <SplideSlide 
          v-for="theGang in theGangs" :key="theGang.id" 
          class="d-flex justify-content-center">
          <GangsterCard 
            :gangId="theGang.id"
            :name="theGang.name" 
            :position="theGang.position" />
        </SplideSlide>
      
      </Splide>
    </div>
    <div class="d-flex justify-content-end right-arrow">
      <img 
        @click="nextSlide"
        class="gang-slide-arrow" 
        src="@/assets/sprites/06_BUDDY_GANGSTER/67_GANGSTER_rightbutton.png" />
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import GangsterCard from './GangsterCard.vue'

export default {
  components: { Splide, SplideSlide, GangsterCard },
  data() {
    return {
      theGangs: [
        { id: "01", name: "Apinnat Chanbunsai", position: "CEO, Queen of Daydreamer" },
        // { id: "02", name: "Last Raven", position: "The Demon Lord Developer" },
        { id: "03", name: "Sarun Leelasuwonvong", position: "CDO, King of Artist" },
        //{ id: "04", name: "Xyclopz", position: "CMO, King of Party" },
        //{ id: "05", name: "Zodd", position: "CTO, King of Developer" },
        //{ id: "06", name: "Knabretta", position: "CFO, Godzilla the Financial Protector" },
        { id: "07", name: "Kittirat Kham-In", position: "CITO,The Lord of Mafia and the New Daddy" },
        { id: "08", name: "Natthapong​ Chinnawong​", position: "Lord of 2D Artist" },
        // { id: "09", name: "xthewiz", position: "Lord of Backend Developer" },
         { id: "10", name: "Chanissara Srijanphothikhun", position: "Marketing" },
         { id: "11", name: "Chawanakorn Worawetmongkol", position: "Game Developer" },
         { id: "12", name: "Tiyanon Sahasukee", position: "Game Developer" },
         { id: "13", name: "Nuttapan Puknah", position: "Game Developer" },
         { id: "14", name: "Anak Ladachat", position: "Best Game Developer" },
         { id: "15", name: "Ploy Lawon", position: "2D Artist" },
         //{ id: "16", name: "HalleLu", position: "Administrator" },
         { id: "17", name: "Praewa Srithong", position: "Marketing" },
         { id: "18", name: "Chutarat Prommar", position: "Marketing" },
      ],
      slideOptions: {
        rewind: true, 
        pagination: false, 
        arrows: false, 
        perPage: 4,
        breakpoints: {
          720: {
            perPage: 2
          },
          480: {
            perPage: 1,
            arrows: true
          }
        }
      }
    }
  },
  methods: {
    nextSlide() {
      this.$refs.splide.go('>')
    },
    prevSlide() {
      this.$refs.splide.go('<')
    }
  }
}
</script>

<style>
.gangster-label {
  max-width: 45%;
}

.gang-panel {
  width: 80%;
}

.gang-slide-arrow {
  cursor: pointer;
  max-width: 40px;
}

.left-arrow {
  max-width: 80px; 
  padding-left: 20px;
}

.right-arrow {
  max-width: 80px; 
  padding-right: 8px;
}

@media screen  and (max-width: 480px){
  .gang-slide-arrow {
    display: none;
  }
}
</style>