import { createApp, inject } from 'vue'
import App from './App.vue'
import router from './router'


import "bootstrap/dist/css/bootstrap.css"
import "@splidejs/splide/dist/css/splide.min.css"

import './assets/css/main.css'




// createApp(App).use(router).mount('#app')

const app = createApp(App).use(router);
app.config.globalProperties.$accWallet = "NONE";
app.config.globalProperties.$boxToken = 0;

app.mount("#app")


import "bootstrap/dist/js/bootstrap"