<script>

const { ethers, BigNumber, utils } = require('ethers')

export default {
    name: 'TreasureToken',
    props: {
        tokenName: String,
        amount: Number,
        unit: String,
        imagePath: String
    },
    data() {
        return {
            walletConnected: false,
            tokenAmount: 0,
            claimable: false,
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onConnectWallet() {
            this.$parent.$emit('walletConnected', walletConnected);
        },

        getClaimableToken: async function(){

            if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]

                if (!account) {
                    alert('No account found')
                } else {
                    this.walletConnected = true;
                }

                try {
                    let params = new URLSearchParams({
                        walletAddress: account
                    })

                    let response = await fetch(
                        `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/claimable-game-token?${params}`, {
                        // `http://localhost:3000/dev/claimable-game-token?${params}`, {
                        mode: 'cors',
                    })

                    let responseJson = await response.json()
                    if (response.status !== 200) {
                        throw new Error(responseJson.message)
                    }
                    
                    
                    this.tokenAmount = responseJson.data.amount
                    if(this.tokenAmount > 0){
                        this.claimable = true
                    }
                   


                     
            }catch (e) {
                    console.error(e)
                    let errorMessage = ''
                    if (e.message.toLowerCase().includes("insufficient")) {
                        this.processingText = "Insufficient Balance"
                    } else {
                        errorMessage = e && e.error ?
                            e.message.length > 60 ?
                                `${e.message.slice(0, 60)}...` : e.message : ''
                        this.processingText = `${e.code}: ${errorMessage}`
                    }
                    this.processingText = `${e.code}: ${errorMessage}`
                    this.showModal = true
                }
            }
        },

        claimBeaterToken: async function () {

            if(this.claimable == false){return}

            const ethereum = window.ethereum
            await ethereum.enable()
            let provider = new ethers.providers.Web3Provider(ethereum)

            // const params = new URLSearchParams({
            //     walletAddress: '0x0000007'
            // })
            // let response = await fetch(
            //     `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/game-token/signature?${params}`, {
            //     mode: 'cors',
            // })

            // let responseJson = await response.json()
            // const { amount, signature } = responseJson.data

            if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]

                if (!account) {
                    alert('No account found')
                } else {
                    this.walletConnected = true;
                }

                try {
                    let params = new URLSearchParams({
                        walletAddress: account
                    })

                    let response = await fetch(
                        `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/claimable-game-token?${params}`, {
                        // `http://localhost:3000/dev/claimable-game-token?${params}`, {
                        mode: 'cors',
                    })

                    let responseJson = await response.json()
                    if (response.status !== 200) {
                        throw new Error(responseJson.message)
                    }
                    
                    
                    const { amount } = responseJson.data

                    const signer = provider.getSigner()
                    const abiGameToken = new ethers.Contract(
                        '0x02185C2C5933a3AAA9AC945374Cd82AA371e6197', // game token address 
                        [{
                            'inputs': [
                                {
                                    'internalType': 'address',
                                    'name': 'to',
                                    'type': 'address'
                                },
                                {
                                    'internalType': 'uint256',
                                    'name': 'maxAmount',
                                    'type': 'uint256'
                                },
                                {
                                    'internalType': 'bytes',
                                    'name': 'signature',
                                    'type': 'bytes'
                                }
                            ],
                            'name': 'claim',
                            'outputs': [],
                            'stateMutability': 'payable',
                            'type': 'function'
                        }, {
                            'inputs': [{ name: '_owner', type: 'address' }],
                            'name': 'balanceOf',
                            'outputs': [{ name: 'balance', type: 'uint256' }],
                            'stateMutability': 'view',
                            'type': 'function'
                        }],
                        signer)

                    const balance = await abiGameToken.balanceOf(account)       // gwei
                    const newBalance = BigNumber.from(balance).add(ethers.utils.parseEther(amount.toString()))      //gwei
                    // console.log(`balanceOf: ${ethers.utils.formatEther(balance)}`)      // number
                    // console.log(`newBalance: ${ethers.utils.formatEther(newBalance)}`)  // number
                    // console.log(`newBalance: ${newBalance}`)    //gwei
                    
                    params.append('claimTokens', parseInt(ethers.utils.formatEther(newBalance)))
                    response = await fetch(
                        `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/game-token/signature?${params}`, {
                        // `http://localhost:3000/dev/game-token/signature?${params}`, {
                        mode: 'cors',
                    })

                    responseJson = await response.json()
                    if (response.status !== 200) {
                        throw new Error(responseJson.message)
                    }
                    

                    const { claimTokens, signature } = responseJson.data
                    if (!claimTokens || !signature) {
                        throw new Error('Missing claimTokens or signature')
                    }

                    const hash = await abiGameToken.claim(
                        account,
                        ethers.utils.parseEther(claimTokens.toString()),
                        signature)

                    const putRequestOptions = {
                        mode: 'cors',
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            walletAddress: account,
                            amount
                        })
                    }
                    response = await fetch(
                        `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/game-token/claim`,
                        // `http://localhost:3000/dev/game-token/claim`,
                        putRequestOptions
                    )
                    responseJson = await response.json()
                    const responseMessage = responseJson.data

                    
                    window.location.reload()
                } catch (e) {
                    console.error(e)
                    let errorMessage = ''
                    if (e.message.toLowerCase().includes("insufficient")) {
                        this.processingText = "Insufficient Balance"
                    } else {
                        errorMessage = e && e.error ?
                            e.message.length > 60 ?
                                `${e.message.slice(0, 60)}...` : e.message : ''
                        this.processingText = `${e.code}: ${errorMessage}`
                    }
                    this.processingText = `${e.code}: ${errorMessage}`
                    this.showModal = true
                }
            }
        }
    },
    beforeMount(){
        this.getClaimableToken()
    },
};

</script>


<template>

    <div class="treasure-item">
        <img  :src="require(`@/assets/sprites/${imagePath}`)" style="height: 20%; ">
        <div>
            
            <h5 style="margin: 0; font-size: 1.4vw; text-align: center; vertical-align: middle; color: #FFFFFF;">{{ tokenName }}</h5>
            <p style="font-size: 1.1vw; text-align: center; color: #FFFFFF;">{{ tokenAmount }} {{ unit }}</p>
        </div>
        <!-- <div style=" display: flex; align-items: center; justify-content: center; width: 100%;">
            <p style="width: 65%; font-size: 1.2vw; text-align: end; color: #FFFFFF;">{{ amount }}</p>
            <p style="width: 30%; font-size: 1.2vw; text-align: start; margin-left: 5px; color: #FFFFFF;">{{ unit }}</p>
        </div> -->
        <!-- <img @click="claimBeaterToken" src="@/assets/sprites/13_INVENTORY/CLAIM_BTN.png"
            style="cursor: pointer; width:70%"> -->
        <button class="btn-claim-token" :class="{'active':this.claimable}" @click="claimBeaterToken">Claim</button>
    </div>

</template>

<style>
.treasure-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    width: 235.66px;
    height: 348.86px;

    
    padding: 10px 8px 0px 8px;
    margin: 0 1.5%;


    background-image: linear-gradient(145.96deg, #E2DCFF -37.12%, #AEA9C4 -13.06%, #817C90 11%, #6F6B7C 22.43%);
    border-radius: 10px;
    border-style: solid;
    border-width: 4px;
    border-color: #3D3849;
}

.btn-claim-token.active {
    width: 70%;
    height: 15%;

    border: 0;
    border-radius: 7px;

    color: #FFF;
    background-image: linear-gradient(109.24deg, #FFED48 -11.61%, #EEA81E 48.65%);;
}

.btn-claim-token {
    width: 70%;
    height: 15%;

    border-width: 2px;
    border-style: solid;
    border-color: rgb(113, 113, 113);
    border-radius: 7px;

    color: #fff;
    background-color: rgb(173, 173, 173);
    background-image: none;
}
</style>