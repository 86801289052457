<template>
  <div class="news-bg" id="bb-news">
    <div v-if="!onlyFeed" class="d-flex align-items-center justify-content-center py-4">
      <img class="left-seeder mb-2"
              src="@/assets/sprites/07_NEWSPAPER/70_NEWS_seederA.png" />  
      <img class="gangster-label" src="@/assets/sprites/07_NEWSPAPER/68_NEWS_head.png" />
    </div>
    <div class="d-flex justify-content-center flex-wrap pb-3">
      <MediumCard v-for="feed in feeds" 
        @click="goToNews(feed.link)"
        class="mx-3 mt-3"
        :date="feed.pubDate" :imgUrl="feed.thumbnail" :title="feed.title" :key="feed.title" />
    </div>
    <div v-if="!onlyFeed" class="d-flex justify-content-center py-4 align-items-center">
      <!-- <a style="width: 50%;" href="https://blog.buddybeater.io/" target="_blank"> -->
        <img class="view-more-btn" @click="openViewMore"
          src="@/assets/sprites/07_NEWSPAPER/72_NEWS_viewmore_active.png" />
      <!-- </a> -->
      <img class="right-seeder mt-2" src="@/assets/sprites/07_NEWSPAPER/71_NEWS_seederB.png" />
    </div>
  </div>

</template>

<script>
import MediumCard from './MediumCard.vue'

export default {
  components: { MediumCard },
  props: ['onlyFeed'],
  data() {
    return {
      feeds: []
    }
  },
  methods: {
    async fetchRssFeed() {
      try {
        const url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40buddybeater'
        const response = await fetch(url)
        const feedJson = await response.json()

        if (feedJson.status === 'ok' && feedJson.items && feedJson.items.length > 0) {
          this.feeds = feedJson.items.slice(0, 3)
        }
      } catch (err) {
        console.error(err)
      }
    },
    openViewMore() {
      window.open('https://blog.buddybeater.io/', '_blank')
    },
    goToNews(link) { 
      window.open(link)
    }
  },
  created() {
    this.fetchRssFeed()
  }
}
</script>

<style>
.news-bg {
  background-image: url('@/assets/sprites/07_NEWSPAPER/69_NEWS_mainBG.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 12px;
  padding-bottom: 12px;
}
.view-more-btn {
  cursor: pointer;
  max-width: 35%;
}
</style>