<template>

  
  <router-view class="app-content-view" />
</template>

<script>

  
export default {

  

  data(){
    return{
      connectedWallet: "NONE"
      
    }
  },
  methods: {
      onWalletConnect(value) {
          
          
      }
  },
  
}
</script>

<style>
body {
  background-color: rgb(87,81,105);
}

.home-bg {
  width: 100%;
  background: rgb(87,81,105);
}


.app-content-view {
  position: absolute;
  top: 0;
}



</style>
