<template>
<div id="account-dropdown">
  <div id="account-dropdown-btn" class="d-flex align-items-center justify-content-evenly" style="border-radius: 14px; cursor: pointer;" >
      <img src="@/assets/sprites/12_PROFILE/MAIN_PROFILE_PIC.png" alt="profile pic" style="height: 80%">
      <div style="display: flex; flex-direction:column; width: 70%; height: 100%; align-items:center; text-align:center;">
        <div style="width: 90%; height: 50%; color:#fff; text-align: center; vertical-align: middle; font-size: .8vw; font-weight: 500; text-overflow:ellipsis; overflow: hidden;">{{playerName}}</div>
        <div style="width: 90%; height: 50%; color:#4D4D4D; text-align: center; vertical-align: middle; font-size: .7vw; background-color: #FFFFFF; border-radius: 8px; padding: 1% 2%; text-overflow:ellipsis; overflow: hidden;">{{walletAddress}}</div>
      </div>

      
  </div>

  <div id="account-dropdown-menu">

    <router-link to="/treasure_box" class="account-dropdown-menu-content">
        <button class="account-dropdown-menu-button">Treasure Box</button>
      </router-link>

      <router-link to="" class="account-dropdown-menu-content">
        <button class="account-dropdown-menu-button" style="cursor: not-allowed; background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));">Manage Account</button>
      </router-link>

      <router-link to="" class="account-dropdown-menu-content">
        <button class="account-dropdown-menu-button" style="cursor: not-allowed; background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));">Store</button>
      </router-link>

      

      <router-link to="" class="account-dropdown-menu-content">
        <button class="account-dropdown-menu-button" style="cursor: not-allowed; background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));">Inventory</button>
      </router-link>
      
      <!-- <router-link to="" class="account-dropdown-menu-content">
        <button class="account-dropdown-menu-button" id="discon-btn">Disconnect</button>
      </router-link> -->
  </div>
  
  
</div>


  
    
  
</template>

<script>
  export default {
    data() {
      return{
        playerName: "",
        walletAddress: "",
      }
    },
    methods:{
      getUserInfo: async function(){
        console.log("PLAYER NAME : ")
        const ethereum = window.ethereum
        await ethereum.enable()

        if (typeof window.ethereum !== 'undefined') {
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
                const account = accounts[0]
                

                

                if (!account) {
                    alert('No account found')
                }
                this.walletAddress = account
                
                

                try {
                    let params = new URLSearchParams({
                        walletAddress: account
                    })

                    let response = await fetch(
                        `https://xo2abpwrn3.execute-api.ap-southeast-1.amazonaws.com/dev/player-settings?${params}`, {
                        
                        mode: 'cors',
                    })

                    let responseJson = await response.json()
                    if (response.status !== 200) {
                        this.playerName = "Unknown"
                        throw new Error(responseJson.message)
                    }

                    console.log("PLAYER NAME : "+responseJson.data.name)
                    this.playerName = responseJson.data.name
                    
                    
                    
                                       
            }catch (e) {
                    console.error(e)
                    let errorMessage = ''
                    if (e.message.toLowerCase().includes("insufficient")) {
                        this.processingText = "Insufficient Balance"
                    } else {
                        errorMessage = e && e.error ?
                            e.message.length > 60 ?
                                `${e.message.slice(0, 60)}...` : e.message : ''
                        this.processingText = `${e.code}: ${errorMessage}`
                    }
                    this.processingText = `${e.code}: ${errorMessage}`
                    this.showModal = true
                }
            }
           
            
      }
    },
    beforeMount(){
      this.getUserInfo();
      
    },
  }
</script>

<style>
#account-dropdown-btn {
  position: relative;

  width: 100%;
  height: 100%;
  max-width: 250px;


  z-index: 2;

  background-color: #1AFFB8;
  border-style: solid;
  border-width: 2.5px;
  border-color: #FFFFFF;

}

#account-dropdown{
  width: 60%;
  height: 60%;
  max-width: 250px;

  position: relative;
}

#account-dropdown-menu{
  display: flex;
  flex-direction: column;

  align-items: center;
  

  position: absolute;
  top: 80%;
  

  width: 100%;
  padding: 8% 0px 4% 0px;

  z-index: 1;

  background-color: #3D3849;
  border-radius: 0 0 8px 8px;

  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}

.account-dropdown-menu-content{
  
  

  width: 90%;
}

.account-dropdown-menu-button{
  
  width: 100%;
  height: 30px;


  margin: 1.6% 0;

  color: #4D4D4D;
  background-image: linear-gradient(to right, #FFED48, #EEA81E);
  
  border: 0;
  border-radius: 6px;

  
}

#discon-btn{
  color:#FFFFFF;
  background-image: linear-gradient(to right, #D89073, #A00000);
}

#account-dropdown:hover > #account-dropdown-menu{
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  
}

</style>