<template>
  <div class="home-bg bb-cover">
    <img class="cover-bg" 
      src="@/assets/sprites/01_HEAD_PART/cover-mixed.png" alt="cover" />

    <img class="btn-join-list" 
	    src="@/assets/sprites/01_HEAD_PART/MintAvatars_button01.png"
      onclick="window.open('https://mint.buddybeater.io/','mywindow');" style="cursor: pointer;"
    />

    <img class="btn-play" 
	    src="@/assets/sprites/01_HEAD_PART/play_active.png"
      onclick="window.open('http://d2hqk6f0yotdbr.cloudfront.net/','mywindow');" style="cursor: pointer;"
    />

    <!--<img class="btn-download" 
	    src="@/assets/sprites/01_HEAD_PART/DownloadClient_WEB_01_active.png"
      @click="downloadLuancher" style="cursor: pointer;"
    />-->

  </div>
</template>

<script>
let AWS = require('aws-sdk')
export default {
  computed: {
    s3Client() {
      return new AWS.S3({
        accessKeyId: "AKIAZ765JIYTPVJ7BC6J",
        secretAccessKey: "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",
        region: 'ap-southeast-1'
      })
    }
  },
  methods: {
    async downloadLuancher() {
      const options = {
        Bucket    : 'buddybeater-installer',
        Key    : 'buddybeater_beta_00-01-00_setup.exe',
      };

      this.s3Client.getObject(options)
        .promise()
        .then(data => {
          const blob = new Blob([data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'buddybeater_beta_00-01-00_setup.exe'
          link.click()
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

<style>
.bb-cover {
  position: relative;
}

.cover-bg {
  position: relative;
  width: 100%;
}

.btn-join-list {
  position: absolute;
  top: 58%;
  left: 50%;
  width: 30%;
  transform: translate(-50%);
}

.btn-download {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 30%;
  transform: translate(-50%);
}

.btn-play {
  position: absolute;
  max-width: 13%;
  top: 72%;
  left: 50%;
  width: 30%;
  transform: translate(-50%);
}

.btn-stp-list {
  position: absolute;
  top: 68%;
  left: 50%;
  width: 30%;
  transform: translate(-50%);
}

.open-soon {
  position: absolute;
  top: 70%;
  left: 50%;
  width: 28%;
  transform: translate(-50%);
}
</style>